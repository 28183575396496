export const setSpMenu = () => {
  const fadeToggle = (element, time = 200, duration = 20) => {
    let opacity = 0
    if (getComputedStyle(element, null).getPropertyValue('display') === 'none') {
      // fadeIn
      opacity = 0
      element.style.opacity = opacity
      element.style.display = 'block'
      const fadeIn = setInterval(() => {
        opacity = opacity + (duration / time)
        element.style.opacity = opacity
        if (opacity >= 1) {
          opacity = 1
          element.style.opacity = opacity
          clearInterval(fadeIn)
        }
      }, duration)
    } else {
      // fadeOut
      opacity = 1
      element.style.opacity = opacity
      const fadeOut = setInterval(() => {
        opacity = opacity - (duration / time)
        element.style.opacity = opacity
        if (opacity <= 0) {
          opacity = 0
          element.style.opacity = opacity
          element.style.display = 'none'
          clearInterval(fadeOut)
        }
      }, duration)
    }
  }

  document.querySelectorAll('.js_menuBtn').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('open')
      document.querySelectorAll('.js_menuBg').forEach((bgEl) => {
        fadeToggle(bgEl)
      })
      document.querySelectorAll('.js-navSp').forEach((navEl) => {
        fadeToggle(navEl)
      })
    })
  })
}
