// Y軸が同じ位置に配置されている要素の高さを揃える
export const matchHeight = () => {
  const adjustHeight = (elements) => {
    let matchHeightGroup = {}
    elements.forEach((el) => {
      const positionY = window.pageYOffset + el.getBoundingClientRect().top
      if (!matchHeightGroup[positionY]) {
        matchHeightGroup[positionY] = {
          maxHeight: 0,
          targets: []
        }
      }
      if (matchHeightGroup[positionY]['maxHeight'] < el.offsetHeight) {
        matchHeightGroup[positionY]['maxHeight'] = el.offsetHeight
      }
      matchHeightGroup[positionY]['targets'].push(el)
    })
    for (const [_key, value] of Object.entries(matchHeightGroup)) {
      Array.prototype.forEach.call(value['targets'], (target) => {
        target.style.height = `${value['maxHeight']}px`
      })
    }
  }
  let targetDatasets = []
  document.querySelectorAll('[data-mh]').forEach((el) => {
    if (!targetDatasets.includes(el.dataset.mh)) {
      targetDatasets.push(el.dataset.mh)
    }
  })
  targetDatasets.forEach((targetDataset) => {
    adjustHeight(document.querySelectorAll(`[data-mh=${targetDataset}]`))
  })
  adjustHeight(document.querySelectorAll('.js_matchHeight'))
}
