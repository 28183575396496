import Swiper from 'swiper/bundle'

export const setTopSlider = () => {
  new Swiper('.js_slider', {
    loop: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 5000
    },
    speed: 500
  })
}
