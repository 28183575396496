import Swiper from 'swiper/bundle'

export const setImgSlider = () => {
  new Swiper('.js_imgSlider', {
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 5000
    },
    speed: 500
  })
}
