export const convertTelephoneLink = () => {
  const UA_iPhone = (navigator.userAgent.indexOf('iPhone') > -1)? true:false
  const UA_android = (navigator.userAgent.indexOf('Android') > -1)? true:false

  if (UA_iPhone || UA_android) {
    document.querySelectorAll('.js_telNum').forEach((el) => {
      const a = document.createElement('a')
      a.setAttribute('href', `tel:${el.dataset.tel}`)
      el.before(a)
      a.append(el)
    })
  }
}
