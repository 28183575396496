export const setPagetopButton = () => {
  const pageHeight = document.querySelector('body').offsetHeight
  const footerHeight = document.querySelector('.footer').offsetHeight
  const contentsHeight = pageHeight - footerHeight
  const setPagetopButtonClass = () => {
    if (window.pageYOffset > 500) {
      document.querySelector('.pagetopBtn').classList.add('appearance')
    } else {
      document.querySelector('.pagetopBtn').classList.remove('appearance')
    }
    const scrollCount = window.pageYOffset + window.innerHeight
    if (scrollCount > contentsHeight) {
      document.querySelector('.pagetopBtn').classList.add('fixed')
    } else {
      document.querySelector('.pagetopBtn').classList.remove('fixed')
    }
  }
  setPagetopButtonClass()
  window.addEventListener('scroll', () => {
    setPagetopButtonClass()
  })
}
