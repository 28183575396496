import { matchHeight } from './user/matchHeight'
import { setSpMenu } from './user/setSpMenu'
import { setAccordion } from './user/setAccordion'
import { setTopSlider } from './user/setTopSlider'
import { setColumnSlider } from './user/setColumnSlider'
import { setImgSlider } from './user/setImgSlider'
import { setPagetopButton } from './user/setPagetopButton'
import { convertTelephoneLink } from './user/convertTelephoneLink'
import { setModal } from './user/setModal'

window.addEventListener('load', () => {
  let isDeviceSp = getComputedStyle(document.querySelector('body'), null).getPropertyValue('position') === 'relative'
  let wasDeviceSp = isDeviceSp

  window.addEventListener('resize', () => {
    isDeviceSp = getComputedStyle(document.querySelector('body'), null).getPropertyValue('position') === 'relative' ? 1 : 0
    const spToPc = wasDeviceSp && !isDeviceSp
    const pcToSp = !wasDeviceSp && isDeviceSp
    if (spToPc) {
      document.querySelectorAll('.js_menuBg').forEach((el) => { el.style.display = 'none' })
      document.querySelectorAll('.js_acoBody_SP').forEach((el) => { el.style.display = '' })
      document.querySelectorAll('.js-navSp').forEach((el) => { el.style.display = '' })
      document.querySelectorAll('.js-subNav').forEach((el) => { el.style.display = '' })
    }
    if (pcToSp) {
      document.querySelectorAll('.js_menuBtn').forEach((el) => { el.classList.remove('open') })
      document.querySelectorAll('.js_menuBg').forEach((el) => { el.style.display = 'none' })
      document.querySelectorAll('.js_acoBody_SP').forEach((el) => { el.classList.remove('open') })
      document.querySelectorAll('.js_acoBody_SP').forEach((el) => { el.style.display = 'none' })
      document.querySelectorAll('.js-navSp').forEach((el) => { el.style.display = '' })
    }

    wasDeviceSp = isDeviceSp
  })

  if (document.querySelector('.js_telNum')) {
    convertTelephoneLink()
  }

  if (document.querySelector('.js_matchHeight') || document.querySelector('[data-mh]')) {
    matchHeight()
  }

  if (document.querySelector('.js_menuBtn')) {
    setSpMenu()
  }

  if ((document.querySelector('.js_acoBtn_SP') ||
    document.querySelector('.js-navAcoBtn')) &&
    isDeviceSp) {
    setAccordion()
  }

  if (document.querySelector('.js_slider')) {
    setTopSlider()
  }

  if (document.querySelector('.js_columnSlider') && !isDeviceSp) {
    setColumnSlider()
  }

  if (document.querySelector('.js_imgSlider')) {
    setImgSlider()
  }

  if (document.querySelector('.pagetopBtn')) {
    setPagetopButton()
  }

  if (document.querySelector('.js_modal')) {
    setModal()
  }

})
