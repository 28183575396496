export const setColumnSlider = () => {
  const columnSlider = document.querySelector('.js_columnSlider')
  // 交互に上下しているデザインなので一度中身をコピーしてコンテンツの数を2の倍数にしておく
  Array.prototype.forEach.call(columnSlider.children, (child) => {
    columnSlider.appendChild(child.cloneNode(true))
  })
  const browserWidth = document.documentElement.scrollWidth
  const scrollLength = columnSlider.offsetWidth
  // 最大スクロール時に画面内にコンテンツの終わりが表示されないように、
  // 画面幅+スクロール量以上のコンテンツ幅になるまで繰り返しコピー
  const loopCount = Math.ceil((browserWidth + scrollLength) / columnSlider.offsetWidth)
  for(var i = 0; i < loopCount; i++) {
    Array.prototype.forEach.call(columnSlider.children, (child) => {
      columnSlider.appendChild(child.cloneNode(true))
    })
  }
  let left = 0
  columnSlider.style.left = `${left}px`
  const speed = 25
  const duration = 20
  const animation = () => {
    left = left - (speed / duration)
    columnSlider.style.left = `${left}px`
    if (left <= -(scrollLength)) {
      left = 0
      columnSlider.style.left = `${left}px`
    }
  }
  let animationInterval = setInterval(animation, duration)
  columnSlider.addEventListener('mouseover', () => { clearInterval(animationInterval) })
  columnSlider.addEventListener('mouseleave', () => { animationInterval = setInterval(animation, duration) })
}
