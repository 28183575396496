export const setModal = () => {
  document.querySelectorAll('.js_modal').forEach((el) => {
    el.addEventListener('click', () => {
      document.querySelectorAll('.js_modal_window').forEach((el) => {
        el.classList.remove('open')
      })
      let target = el.dataset.target
      document.getElementById(target).classList.add('open')
    })
  })
  document.querySelectorAll('.js_modal_close').forEach((el) => {
    el.addEventListener('click', () => {
      document.querySelectorAll('.js_modal_window').forEach((el) => {
        el.classList.remove('open')
      })
    })
  })
}
