export const setAccordion = () => {
  const slideToggle = (element, time = 200, duration = 20) => {
    let height = 0
    if (getComputedStyle(element, null).getPropertyValue('display') === 'none') {
      // slideOpen
      element.style.display = 'block'
      element.style.overflow = 'hidden'
      // open時のheightを取得して閉じる
      element.style.height = ''
      const naturalHeight = element.clientHeight
      height = 0
      element.style.height = `${height}px`
      const slideOpen = setInterval(() => {
        height = height + (naturalHeight * (duration / time))
        element.style.height = `${height}px`
        if (height >= naturalHeight) {
          height = naturalHeight
          element.style.height = `${height}px`
          clearInterval(slideOpen)
        }
      }, duration)
    } else {
      // slideClose
      element.style.overflow = 'hidden'
      element.style.height = ''
      const naturalHeight = element.clientHeight
      height = element.clientHeight
      const slideClose = setInterval(() => {
        height = height - (naturalHeight * (duration / time))
        element.style.height = `${height}px`
        if (height <= 0) {
          height = 0
          element.style.height = `${height}px`
          element.style.display = 'none'
          clearInterval(slideClose)
        }
      }, duration)
    }
  }

  document.querySelectorAll('.js_acoBtn_SP').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('open')
      if (e.currentTarget.nextElementSibling.classList.contains('js_acoBody_SP')) {
        slideToggle(e.currentTarget.nextElementSibling)
      }
    })
  })
  document.querySelectorAll('.js-navAcoBtn').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('open')
      if (e.currentTarget.nextElementSibling.classList.contains('js-subNav')) {
        slideToggle(e.currentTarget.nextElementSibling)
      }
    })
  })
}
